<template>
  <div class="roadSectionEditor">
    <h2>Road Section Details</h2>
    <div class="w-full flex flex-row">
      <div class="flex">
        <div class="one w-28 flex items-center">
          <span>Name</span>
        </div>
        <input
            type="text"
            placeholder="Name"
            v-model="entry.Name"
            class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
        />
      </div>
      <div class="three w-28 ml-4 flex items-center">
        <span>Route_Type</span>
      </div>
      <input
          type="text"
          placeholder="Route_Type"
          v-model="entry.Route_Type"
          class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
      />
    </div>

    <div class="mt-2 w-full flex flex-row">
      <div class="flex">
        <div class="w-28 flex items-center">
          <span>Type</span>
        </div>
        <input
            type="text"
            placeholder="Type"
            v-model="entry.Type"
            class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
        />
      </div>
      <div class="w-28 ml-4 flex items-center">
        <span>Itinerary</span>
      </div>
      <input
          type="text"
          placeholder="Itinerary"
          v-model="entry.Itinerary"
          class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
      />
    </div>

    <div class="mt-2 w-full flex flex-row">
      <div class="flex">
        <div class="w-28 flex items-center">
          <span>Lower_Date</span>
        </div>
        <input
            type="text"
            placeholder="Lower_Date"
            v-model="entry.Lower_Date"
            class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
        />
      </div>
      <div class="w-28 ml-4 flex items-center">
        <span>Low_Date_E</span>
      </div>
      <input
          type="text"
          placeholder="Low_Date_E"
          v-model="entry.Low_Date_E"
          class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
      />
    </div>

    <div class="mt-2 w-full flex flex-row">
      <div class="flex">
        <div class="w-28 flex items-center">
          <span>Upper_Date</span>
        </div>
        <input
            type="text"
            placeholder="Upper_Date"
            v-model="entry.Upper_Date"
            class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
        />
      </div>
      <div class="w-28 ml-4 flex items-center">
        <span>Up_Date_E</span>
      </div>
      <input
          type="text"
          placeholder="Up_Date_E"
          v-model="entry.Up_Date_E"
          class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
      />
    </div>

    <div class="mt-2 w-full flex flex-row">
      <div class="flex">
        <div class="w-28 flex items-center">
          <span>Cons_per_e</span>
        </div>
        <input
            type="text"
            placeholder="Cons_per_e"
            v-model="entry.Cons_per_e"
            class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
        />
      </div>
      <div class="w-28 ml-4 flex items-center">
        <span>Segment_s</span>
      </div>
      <input
          type="text"
          placeholder="Segment_s"
          v-model="entry.Segment_s"
          class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
      />
    </div>

    <div class="mt-2 w-full flex flex-row">
      <div class="w-full flex items-center">
        <span class="w-28">Descriptio</span>
        <TextArea
            placeholder="Descriptio"
            :value="entry.Descriptio"
            class="mr-4 flex-grow block"
            @input="value => entry.Descriptio = value"
            @change="value => entry.Descriptio = value"
        />
      </div>
    </div>

    <div class="mt-2 w-full flex flex-row">
      <div class="flex">
        <div class="w-28 flex items-center">
          <span>Citation</span>
        </div>
        <input
            type="text"
            placeholder="Citation"
            v-model="entry.Citation"
            class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
        />
      </div>
      <div class="w-28 ml-4 flex items-center">
        <span>Bibliograp</span>
      </div>
      <input
          type="text"
          placeholder="Bibliograp"
          v-model="entry.Bibliograp"
          class="w-72 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
      />
    </div>

    <hr>
    <div class="flex items-center">
      <div class="w-28 flex items-center">
        <span>Authors</span>
      </div>
      <input
          class="flex-grow mr-4 py-2 px-3 block rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
          type="text"
          placeholder="Authors (Pattern: F. Familyname, S. Familyname, ...)"
          v-model="entry.Authors"
      />
    </div>
    <hr>

    <div class="mt-2 w-full flex flex-row">
      <div class="flex">
        <div class="w-28 flex items-center">
          <span>uploader</span>
        </div>
        <span
            class="w-72 h-9 px-3 flex items-center"
        >
        {{ entry.uploader }}
      </span>
      </div>
      <div class="w-28 ml-4 flex items-center">
        <span>uploaded</span>
      </div>
      <span
          class="w-72 h-9 px-3 flex items-center"
      >
        {{ getDateTimeString(entry.uploaded) }}
      </span>
    </div>

    <div class="mt-2 w-full">
      <div class="flex items-center">
        <div class="w-28 flex items-center">
          <span>fileName</span>
        </div>
        <span
            class="h-9 px-3 flex items-center"
        >
        {{ entry.fileName }}
      </span>
      </div>
    </div>

    <div class="mt-2 w-full">
      <div class="flex items-center">
        <div class="w-28 flex items-center">
          <span>URI</span>
        </div>
        <span
            class="h-9 px-3 flex items-center">
          {{ entry.uri }}
        </span>
      </div>
    </div>

    <hr>

    <div class="w-full flex-wrap justify-between">
      <div class="flex items-center justify-between">
        <button
            v-if="user.isAdmin || user.email === entry.uploader"
            class="rounded w-56 mt-4 h-9 bg-red-700 text-white"
            @click="onDeleteRoadSection"
        >DELETE ENTRY
        </button>
        <a :href="entry.uri" target="_blank">
          <button class="rounded w-56 mt-4 h-9 bg-blue-900 text-white"
          >VIEW ENTRY
          </button>
        </a>
        <button
            class="rounded w-56 mt-4 h-9 bg-blue-900 text-white"
            @click="onSave"
        >SAVE ENTRY
        </button>
      </div>
    </div>

  </div>
</template>

<script>

import {useStore} from "vuex";
import {DELETE_ENTRY, PUT_ENTRY} from "@/store/operations";
import {computed, ref} from "vue";
import TextArea from "@/components/TextArea";
import dayjs from "dayjs";
import {isString} from "@/lib/getVariableType";

export default {
  name: "RoadSectionEditor",
  components: {TextArea},
  emits: ['abort', 'saved', 'done'],
  props: {
    section: {
      type: Object,
      required: true
    },
  },
  setup(props, {emit}) {
    const store = useStore()
    const user = computed(() => store.state.user)
    const originalEntry = ref({...props.section.properties})
    const entry = ref(props.section.properties)
    const authors = ref(props.section.properties.Authors ?? '')

    const getDateTimeString = timestamp => dayjs(timestamp).format('DD MMM YYYY HH:mm:ss')

    const onDeleteRoadSection = () => {
      store.dispatch(DELETE_ENTRY, props.section.uuid)
          .then(() => emit('done'))
    }

    const onSave = () => {
      store.dispatch(
          PUT_ENTRY,
          {
            uuid: props.section.uuid,
            patch: Object.fromEntries(
                Object.keys(entry.value)
                .map(k => {
                  const value = entry.value[k]
                  if (isString(value)) return [k, value.trim()]
                  return [k, value]
                })
            ),
          }
      )
      emit('saved')
    }
    const onAbort = () => {
      entry.value = originalEntry.value
      emit('abort')
    }

    return {
      authors,
      entry,
      getDateTimeString,
      onAbort,
      onDeleteRoadSection,
      onSave,
      user,
    }
  }
}
</script>

<style scoped>

</style>
