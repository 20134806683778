<template>
  <div class="dataTable">
    <table
        class="w-full divide-y divide-gray-300"
        id="dataTable"
    >
      <thead class="bg-black">
      <tr>
        <th
            v-for="(header, index) in attributeNames"
            :key="index"
            class="px-6 py-2 text-xs text-white"
        >
          {{ header }}
        </th>
      </tr>
      </thead>
      <tbody v-if="rows?.[0][keyFieldName]">
        <tr
            v-for="(row, index) in rows"
            :key="row[keyFieldName]"
            class="border-b border-black"
            :class="{'bg-yellow-100': index === selectedIndex}"
        >
          <td
              v-for="path in columnHeaders"
              :key="`${index}-${path}`"
              class="text-center"
              @click="() => onSelectField(index, path)"
          >
            {{ getPropertyAtPath(row, path) ?? null }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {isArray} from "@/lib/getVariableType.js";
import {ref} from "vue";
import {getPropertyAtPath} from "@/lib/getPropertyAtPath";
import {useStore} from "vuex";

export default {
  name: "DataTable",
  emits: ['select'],
  props: {
    columnHeaders: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      default: () => [],
      validator: value => isArray(value)
    },
    keyFieldName: {
      type: String,
      default: 'id'
    }
  },
  setup (props, {emit}) {
    const store = useStore()
    const attributeNames = ref(props.columnHeaders.map(head => head.split('.').pop()))
    const selectedIndex = ref(-1)
    const onSelectField = (index, fieldName) => {
      selectedIndex.value = index
      if (store.state.user.isAdmin) emit('select', {index, fieldName})
    }

    return {
      attributeNames,
      getPropertyAtPath,
      onSelectField,
      selectedIndex,
    }
  }
}
</script>

<style scoped>

</style>
