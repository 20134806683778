<template>
  <div
      class="modalWindow window fixed h-screen w-screen top-0 left-0 flex flex-col bg-transparent"
      @click="onClose"
      :style="computedStyleToEnforceZIndex"
  >
    <div
        class="m-auto flex justify-center align-center"
        @click="onKeepOpen"
    >
      <div
          class="overflow-y-auto overflow-x-hidden md:inset-0 h-modal md:h-full justify-center items-center"
          :style="modalStyle"
      >
        <div
            class="relative top-0 left-0 w-full h-full md:h-auto"
            :style="computedStyleToEnforceZIndex"
        >

          <slot>
n o c o n t e n t
          </slot>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";

export default {
  name: "ModalWindow",
  emits: ['close'],
  props: {
    width: {
      type: String,
      default: ''
    }
  },
  setup(props, {emit}) {
    const modalStyle = computed(() => ({
      width: props.width
    }))
    const computedStyleToEnforceZIndex = computed(() => ({
      'z-index': 9999999,
      opacity: 0.9999,
      transform: 'rotate(360deg)'
    }))
    const onClose = () => emit('close')
    const onKeepOpen = event => event.stopPropagation()

    return {
      computedStyleToEnforceZIndex,
      modalStyle,
      onKeepOpen,
      onClose,
    }
  }
}
</script>

<style scoped>
</style>
