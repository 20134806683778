<template>
  <div class="search m-auto">

    <div class="mt-1 flex items-center">
      <span class="w-36">Name</span>
      <input
          type="text"
          placeholder="Name"
          v-model="Name"
          class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
      />
    </div>

    <div class="mt-1 flex items-center justify-evenly gap-6">
      <div class="w-full flex items-center">
        <span class="w-40">Route_Type</span>
        <input
            type="text"
            placeholder="Route_Type"
            v-model="Route_Type"
            class="flex-grow w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
      </div>

      <div class="w-full flex items-center">
        <span class="w-40">Type</span>
        <input
            type="text"
            placeholder="Type"
            v-model="Type"
            class="flex-grow w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
      </div>
    </div>

    <div class="mt-1 flex items-center justify-between gap-6">
      <div class="w-full flex">
        <div class="w-full flex items-center ">
          <span class="w-32">Lower_Date </span>
          <div class="flex justify-between flex-grow">
            <input
                type="text"
                placeholder="Lower_Date from"
                v-model="Lower_Date_from"
                class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
            <input
                type="text"
                placeholder="Lower_Date till"
                v-model="Lower_Date_till"
                class="w-full ml-2 py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
          </div>
        </div>
        <div class="ml-6 w-full flex items-center">
          <span class="w-32">Low_Date_E </span>
          <div class="flex justify-between flex-grow">
            <input
                type="text"
                placeholder="Low_Date_E from"
                v-model="Low_Date_E_from"
                class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
            <input
                type="text"
                placeholder="Low_Date_E till"
                v-model="Low_Date_E_till"
                class="w-full ml-2 py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1 flex items-center justify-between gap-6">
      <div class="w-full flex">
        <div class="w-full flex items-center ">
          <span class="w-32">Upper_Date </span>
          <div class="flex justify-between flex-grow">
            <input
                type="text"
                placeholder="Upper_Date from"
                v-model="Upper_Date_from"
                class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
            <input
                type="text"
                placeholder="Upper_Date till"
                v-model="Upper_Date_till"
                class="w-full ml-2 py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
          </div>
        </div>
        <div class="ml-6 w-full flex items-center">
          <span class="w-32">Up_Date_E </span>
          <div class="flex justify-between flex-grow">
            <input
                type="text"
                placeholder="Up_Date_E from"
                v-model="Up_Date_E_from"
                class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
            <input
                type="text"
                placeholder="Up_Date_E till"
                v-model="Up_Date_E_till"
                class="w-full ml-2 py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1 flex items-center">
      <span class="w-36">Descriptio</span>
      <input
          type="text"
          placeholder="Descriptio"
          v-model="Descriptio"
          class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
      />
    </div>
    <div class="mt-1 flex items-center">
      <span class="w-36">Citation</span>
      <input
          type="text"
          placeholder="Citation"
          v-model="Citation"
          class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
      />
    </div>
    <div class="mt-1 flex items-center">
      <span class="w-36">Bibliograp</span>
      <input
          type="text"
          placeholder="Bibliograp"
          v-model="Bibliograp"
          class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
      />
    </div>

    <div class="mt-1 flex items-center justify-evenly gap-6">
      <div class="w-full flex items-center">
        <span class="w-40">Cons_per_e</span>
        <input
            type="text"
            placeholder="Cons_per_e"
            v-model="Cons_per_e"
            class="flex-grow w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
      </div>

      <div class="w-full flex items-center">
        <span class="w-40">Itinerary</span>
        <input
            type="text"
            placeholder="Itinerary"
            v-model="Itinerary"
            class="flex-grow w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
      </div>
    </div>

    <div class="mt-1 flex items-center justify-evenly gap-6">
      <div class="w-full flex items-center">
        <span class="w-40">Segment_s</span>
        <input
            type="text"
            placeholder="Segment_s"
            v-model="Segment_s"
            class="flex-grow w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
      </div>

      <div class="w-full flex items-center">
        <span class="w-40">URI</span>
        <input
            type="text"
            placeholder="uri"
            v-model="uri"
            class="flex-grow w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
        />
      </div>
    </div>

    <div class="mt-1 flex items-center justify-between gap-6">
      <div class="w-full flex">
        <div class="w-full flex items-center ">
          <span class="w-32">Upload date</span>
          <div class="flex justify-between flex-grow">
            <input
                type="text"
                placeholder="from date"
                v-model="uploadedDateFrom"
                class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
            <input
                type="text"
                placeholder="till date"
                v-model="uploadedDateTill"
                class="w-full ml-2 py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
            />
          </div>
        </div>
        <div class="ml-6 w-full flex items-center">
          <span class="w-32">Uploader</span>
          <input
              type="text"
              placeholder="uploader"
              v-model="uploader"
              class="flex-grow w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
          />
        </div>
      </div>
    </div>
    <div class="mt-1 flex items-center">
      <span class="w-36">File name</span>
      <input
          type="text"
          placeholder="Uploaded file name"
          v-model="uploadedFileName"
          class="w-full py-2 px-3 block rounded-md shadow-sm border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50 disabled:bg-gray-100"
      />
    </div>

    <div class="flex flex-row items-center justify-between">
      <button
          class="rounded w-64 mt-4 py-2 bg-gray-500 text-white"
          @click="onReset"
      >RESET
      </button>
      <div class="flex items-center ">
        <button
            class="rounded w-64 mt-4 py-2 bg-blue-900 text-white"
            @click="onSearch"
        >SEARCH
        </button>
      </div>

    </div>
  </div>

</template>

<script>
import {ref} from "vue";

export default {
  name: "Search",
  emits: ['filter'],
  props: {
    filter: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, {emit}) {
    const initialFilter = ref({})

    const Name = ref(props.filter.Name ?? '')
    const Route_Type = ref(props.filter.Route_Type ?? '')
    const Type = ref(props.filter.Type ?? '')
    const Lower_Date_from = ref(props.filter.Lower_Date_from ?? '')
    const Lower_Date_till = ref(props.filter.Lower_Date_till ?? '')
    const Low_Date_E_from = ref(props.filter.Low_Date_E_from ?? '')
    const Low_Date_E_till = ref(props.filter.Low_Date_E_till ?? '')
    const Upper_Date_from = ref(props.filter.Upper_Date_from ?? '')
    const Upper_Date_till = ref(props.filter.Upper_Date_till ?? '')
    const Up_Date_E_from = ref(props.filter.Up_Date_E_from ?? '')
    const Up_Date_E_till = ref(props.filter.Up_Date_E_till ?? '')
    const Descriptio = ref(props.filter.Descriptio ?? '')
    const Citation = ref(props.filter.Citation ?? '')
    const Bibliograp = ref(props.filter.Bibliograp ?? '')
    const Cons_per_e = ref(props.filter.Cons_per_e ?? '')
    const Itinerary = ref(props.filter.Itinerary ?? '')
    const Segment_s = ref(props.filter.Segment_s ?? '')
    const uploadedDateFrom = ref(props.filter.uploadedDateFrom ?? '')
    const uploadedDateTill = ref(props.filter.uploadedDateTill ?? '')
    const uploader = ref(props.filter.uploader ?? '')
    const uploadedFileName = ref(props.filter.uploadedFileName ?? '')
    const uri = ref(props.filter.uri ?? '')

    const onReset = () => {
      Name.value = initialFilter.value?.Name ?? ''
      Route_Type.value = initialFilter.value?.Route_Type ?? ''
      Type.value = initialFilter.value?.Type ?? ''
      Lower_Date_from.value = initialFilter.value?.Lower_Date_from ?? ''
      Lower_Date_till.value = initialFilter.value?.Lower_Date_till ?? ''
      Low_Date_E_from.value = initialFilter.value?.Low_Date_E_from ?? ''
      Low_Date_E_till.value = initialFilter.value?.Low_Date_E_till ?? ''
      Upper_Date_from.value = initialFilter.value?.Upper_Date_from ?? ''
      Upper_Date_till.value = initialFilter.value?.Upper_Date_till ?? ''
      Up_Date_E_from.value = initialFilter.value?.Up_Date_E_from ?? ''
      Up_Date_E_till.value = initialFilter.value?.Up_Date_E_till ?? ''
      Descriptio.value = initialFilter.value?.Descriptio ?? ''
      Citation.value = initialFilter.value?.Citation ?? ''
      Bibliograp.value = initialFilter.value?.Bibliograp ?? ''
      Cons_per_e.value = initialFilter.value?.Cons_per_e ?? ''
      Itinerary.value = initialFilter.value?.Itinerary ?? ''
      Segment_s.value = initialFilter.value?.Segment_s ?? ''
      uploadedDateFrom.value = initialFilter.value?.uploadedDateFrom ?? ''
      uploadedDateTill.value = initialFilter.value?.uploadedDateTill ?? ''
      uploader.value = initialFilter.value?.uploader ?? ''
      uploadedFileName.value = initialFilter.value?.uploadedFileName ?? ''
      uri.value = initialFilter.value?.uri ?? ''
      emit('filter', generateFilter())
    }

    const generateFilter = () => {
      const filter = {}
      if (Name.value) filter.Name = Name.value
      if (Route_Type.value) filter.Route_Type = Route_Type.value
      if (Type.value) filter.Type = Type.value
      if (Lower_Date_from.value) filter.Lower_Date_from = Lower_Date_from.value
      if (Lower_Date_till.value) filter.Lower_Date_till = Lower_Date_till.value
      if (Low_Date_E_from.value) filter.Low_Date_E_from = Low_Date_E_from.value
      if (Low_Date_E_till.value) filter.Low_Date_E_till = Low_Date_E_till.value
      if (Upper_Date_from.value) filter.Upper_Date_from = Upper_Date_from.value
      if (Upper_Date_till.value) filter.Upper_Date_till = Upper_Date_till.value
      if (Up_Date_E_from.value) filter.Up_Date_E_from = Up_Date_E_from.value
      if (Up_Date_E_till.value) filter.Up_Date_E_till = Up_Date_E_till.value
      if (Descriptio.value) filter.Descriptio = Descriptio.value
      if (Citation.value) filter.Citation = Citation.value
      if (Bibliograp.value) filter.Bibliograp = Bibliograp.value
      if (Cons_per_e.value) filter.Cons_per_e = Cons_per_e.value
      if (Itinerary.value) filter.Itinerary = Itinerary.value
      if (Segment_s.value) filter.Segment_s = Segment_s.value
      if (uploadedDateFrom.value) filter.uploadedDateFrom = uploadedDateFrom.value
      if (uploadedDateTill.value) filter.uploadedDateTill = uploadedDateTill.value
      if (uploader.value) filter.uploader = uploader.value
      if (uploadedFileName.value) filter.uploadedFileName = uploadedFileName.value
      if (uri.value) filter.uri = uri.value
      return filter
    }

    const onSearch = () => emit('filter', generateFilter())

    return {
      Name,
      Route_Type,
      Type,
      Lower_Date_from,
      Lower_Date_till,
      Low_Date_E_from,
      Low_Date_E_till,
      Upper_Date_from,
      Upper_Date_till,
      Up_Date_E_from,
      Up_Date_E_till,
      Descriptio,
      Citation,
      Bibliograp,
      Cons_per_e,
      Itinerary,
      Segment_s,
      uploadedDateFrom,
      uploadedDateTill,
      uploader,
      uploadedFileName,
      uri,
      onSearch,
      onReset,
    }
  },
}
</script>

<style scoped>

</style>
