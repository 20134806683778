<template>
  <div class="textarea">
    <label
        v-if="label"
        class="mb-2 block text-sm font-medium"
    >{{ label }}</label>
    <textarea
        ref="input"
        v-bind="$attrs"
        :style="textareaStyle"
        :value="value"
        :placeholder="placeholder"
        autofocus
        class="textArea w-full p-3 rounded-md border border-gray-300 focus:border-nabBlue-400 focus:outline-none focus:ring focus:ring-nabBlue-200 focus:ring-opacity-50"
        :tabindex="tabIndex"
        @change="onChange"
        @input="onInput"
        @blur="onBlur"
        @keydown.esc="onAbort"
        @keydown.tab.prevent="onTab"
        @keydown="onKeydown"
    ></textarea>
  </div>
</template>

<script>
import {computed, nextTick, ref, watch} from 'vue'
import {isNumber} from "@/lib/getVariableType";
import {getDOMElementHeight} from "@/lib/getDOMElementHeight";

export default {
  name: 'TextArea',
  emits: ['abort', 'change', 'input', 'delete'], // , 'focus', 'blur'
  props: {
    minLines: {
      type: Number,
      default: 1,
      validate: newValue => isNumber(newValue) && newValue > 0
    },
    isFocused: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    tabIndex: {
      type: Number,
      default: -1
    },
    value: {
      default: ''
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  setup(props, {emit}) {
    const isFocused = computed(() => props.isFocused)
    const input = ref(null)
    const originalValue = ref(props.value)
    const inputValue = computed(() => input.value?.value)
    const textareaStyle = computed(() => {
      return {minHeight: `${props.minLines || 1}rem`}
    })

    const onAbort = () => {
      emit('abort', originalValue.value)
      resize()
    }
    const onBlur = e => {
      emit('change', e.target.value)
      resize()
    }
    const onChange = e => {
      emit('change', e.target.value)
      resize()
    }
    const onInput = e => {
      emit('input', e.target.value)
      resize()
    }
    const onTab = () => {
      const el = input.value
      let start = el.selectionStart
      let end = el.selectionEnd
      el.value = el.value.substring(0, start) + "\t" + el.value.substring(end)
      el.selectionEnd = el.selectionStart = start + 1
    }

    const resize = () => {
      const textarea = input.value
      if (textarea) {
        const height = getDOMElementHeight(textarea, {borders: true})
        textarea.style.height = `${height}px`
      }
    }

    watch(inputValue, () => resize(), {immediate: true})
    watch(isFocused, value => {
      if (input.value) {
        if (value) {
          nextTick(() => {
            input.value.focus()
            resize()
          })
        }
      }
    }, {immediate: true})

    const onKeydown = e => {
      const isDeleteKey = e.key === 'Backspace' || e.keyCode === 8 || e.code === 'Backspace'
      const isEmpty = e.target.value === ''
      if (isDeleteKey && isEmpty) emit('delete')
    }

    return {
      input,
      onAbort,
      onBlur,
      onChange,
      onInput,
      onKeydown,
      onTab,
      textareaStyle,
    }
  },
}
</script>

<style scoped>

</style>
