<template>
  <div class="table container w-full px-2">
    <h1 class="px-2 py-8 flex items-center font-sans font-bold break-normal text-indigo-500text-xl md:text-2xl">
      Road Sections: Tabular View
    </h1>

    <ModalWindow
        v-if="selectedRow && shouldShowEntry"
        class="details m-auto"
        @close="onDone"
        width="960px"
    >
      <template #default>
        <div class="rounded-lg">
          <RoadSectionEditor
              class="m-8 p-8 border border-black shadow shadow-xl bg-yellow-100"
              :section="selectedRow"
              @saved="onDone"
              @done="onDone"
          ></RoadSectionEditor>
        </div>
      </template>
    </ModalWindow>

    <Search
        :filter="filter"
        @filter="onFilter"
    ></Search>

    <div class="container">
      <div class="flex flex-col">
        <div class="w-full">
          <div class="p-8 border-b border-gray-200 shadow">

            <DataTable
                v-if="rows.length"
                class="mt-6"
                :columnHeaders="columnHeaders"
                keyFieldName="uuid"
                :rows="rows"
                @select="onSelectField"
            ></DataTable>

            <p v-else>no entries yet</p>
          </div>
          <div class="flex items-center ">
            <div class="download">
              <div class="flex items-center ">
                <button
                    class="rounded w-72 mt-4 py-2 bg-blue-900 text-white"
                    @click="onDownload"
                    :disabled="downloading"
                >
                  <span v-if="!downloading">DOWNLOAD AS ZIP</span>
                  <span v-else>DOWNLOADING...</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, ref} from "vue";
import RoadSectionEditor from "@/components/RoadSectionEditor";
import DataTable from "@/components/DataTable";
import ModalWindow from "@/components/ModalWindow";
import Search from "@/components/Search";
import {useStore} from "vuex";
import {GET_ENTRIES} from "@/store/operations";
import {isObject, isString} from "@/lib/getVariableType";
import {downloadZippedList} from "@/lib/downloadZippedList";
import {getVisibleFieldNames} from "@/lib/fieldNames";

const matchString = (filter, item, key) => (item.properties?.[key] ?? '')
    .includes(filter[key])
const matchRange = (filter, item, key) => {
  let match
  const name = key.substring(0, key.length - 5)
  if (key.endsWith('_from')) match = item.properties?.[name] >= filter[key]
  else match = item.properties?.[name] < filter[key]
  return match
}
const matchByKey = (filter, item, key) => {
  let match
  if (key.endsWith('_from') || key.endsWith('_till')) match = matchRange(filter, item, key)
  else if (isString(item.properties?.[key])) match = matchString(filter, item, key)
  else match = item.properties[key] ?? null === filter[key] ?? ''
  return match
}
const matchesFilterAttributes = (filter, item) =>
    Object.keys(filter).reduce((match, key) =>
            match && matchByKey(filter, item, key),
        true
    )

export default {
  name: "Table",
  emits: ['select'],
  components: {
    DataTable,
    ModalWindow,
    RoadSectionEditor,
    Search,
  },
  setup() {
    const store = useStore()
    const downloading = ref(false)
    const columnHeaders = ref(getVisibleFieldNames())

    const filter = ref({})

    const rows = computed(() =>
        Object.values(store.state.entries)
            .reduce((rows, row) => {
              if (!isObject(filter.value)) rows.push({...row})
              else if (matchesFilterAttributes(filter.value, {...row})) rows.push(row)
              return rows
            }, []) ?? []
    )

    const selectedIndex = ref(-1)
    const selectedField = ref('')
    const shouldShowEntry = ref(false)
    const selectedRow = computed(() => {
      let row
      if (selectedIndex.value >= 0) row = {...rows.value[selectedIndex.value]}
      return row
    })

    const onDone = () => shouldShowEntry.value = false
    const onSelectField = ({index, fieldName}) => {
      selectedIndex.value = index
      selectedField.value = fieldName
      shouldShowEntry.value = true
    }

    const onFilter = value => filter.value = value
    const onDownload = () => {
      downloading.value = rows.value?.length ?? false
      downloadZippedList('table_search', rows.value, filter.value)
    }

    store.dispatch(GET_ENTRIES)

    return {
      columnHeaders,
      downloading,
      filter,
      onDone,
      onDownload,
      onFilter,
      onSelectField,
      rows,
      selectedField,
      selectedIndex,
      selectedRow,
      shouldShowEntry,
    }
  }
}
</script>

<style scoped>

</style>
